import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const currentAuth = authService.currentAuthValue;
  if (currentAuth) {
    return true;
  }

  // return true; // For development purposes only. Remove this line when you start to use this guard.
  // not logged in so redirect to login page with the return url
  router.navigate(['/auth/login']);
  return false;
};
